import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class RedirectWhitelistService {
    private basePath = environment.apiUrl; // Replace with actual base path

    constructor(private http: HttpClient) {}

    validateRedirectUrl(callbackUrl: string): Observable<RedirectValidationResult> {
        const endpoint = `${this.basePath}/api/redirect/validate`;
        const params = new HttpParams().set('callbackUrl', callbackUrl || '');

        return this.http.get<RedirectValidationResult>(endpoint, { params });
    }
}

interface RedirectValidationResult {
    valid: boolean;
    message: string;
}
